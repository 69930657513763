




































































































































































































import Vue from 'vue';
import SingleTermInput from '@/components/Forms/SingleTermInput.vue';
import RangeInput from '@/components/Forms/RangeInput.vue';
import ItemSelectList from '../Item/ItemSelectList.vue';
import {
  ItemParam,
  ScheduleParam,
} from '../../model/product/param/productSaleInfoParam';
import { Dropdown, DropdownItem, DropdownMenu } from 'element-ui';
import { getScheduleStatusLabel } from '../../util/getScheduleStatusLabel';
import { ScheduleState } from '../../model/product/enum';
import { DAY_MILLISECOND } from '@/common/util/day/getConstants';
import { StatusLabel } from '@/common/model/statusLabel';
import { isHostAdmin } from '@/env';
import { SCHEDULING_TERM_MONTH } from '@/domain/product/constants/scheduleConstant';

export default Vue.extend({
  name: 'ScheduleForm',
  components: {
    SingleTermInput,
    RangeInput,
    ItemSelectList,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  props: {
    schedule: {
      type: Object,
    },
    items: {
      type: Array,
    },
    inventoryTargetType: {
      type: String,
    },
    isSale: {
      type: Boolean,
    },
    bookingConfirmationEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        id: this.schedule.id,
        appliedAllItems: this.schedule.appliedAllItems,
        title: this.schedule.title,
        saleTerm: this.schedule.saleTerm,
        term: {
          startedAt: this.schedule.term.startedAt,
          endedAt: this.schedule.term.startedAt + this.schedule.term.duration,
          duration: this.schedule.term.duration,
        },
        itemParamIds: this.schedule.itemParamIds,
        minimumQuota: this.schedule.minimumQuota,
        quota: this.schedule.quota,
        planParamId: this.schedule.planParamId,
        status: this.schedule.status,
      },
      planFormModal: false,
      itemSelectModal: false,
      itemList: this.items as ItemParam[],
      isAdmin: !isHostAdmin(),
      saleTermEndedAt: this.$moment()
        .clone()
        .add(SCHEDULING_TERM_MONTH, 'month')
        .endOf('date') // 현재로부터 3개월 이후 23시 59분 59초까지
        .valueOf(),
    };
  },
  computed: {
    isRepeatSchedule(): boolean {
      return this.schedule.planParamId ? true : false;
    },
    selectedItems(): ItemParam[] {
      return this.itemList.filter((item: ItemParam) =>
        this.form.itemParamIds.includes(item.paramId)
      );
    },
    canPause(): boolean {
      return (
        this.schedule.status &&
        [ScheduleState.OPENED].includes(this.schedule.status)
      );
    },
    canEdit(): boolean {
      return (
        !this.schedule.status || this.schedule.status === ScheduleState.EDITING
      ); // 스케줄의 상태가 최초 등록이거나 등록중 상태일 경우 수정가능
    },
    statusLabel(): StatusLabel {
      return getScheduleStatusLabel(this.form.status);
    },
    onlyQuotaLimit: {
      get(): boolean {
        return this.form.minimumQuota === 0;
      },
      set(newValue: boolean) {
        if (newValue) {
          this.form.minimumQuota = 0;
        }
      },
    },
  },
  created() {
    this.itemList.forEach(item => {
      item.checked = false;
      if (this.form.itemParamIds.includes(item.paramId)) {
        item.checked = true;
      }
    });
  },
  methods: {
    saveSchedule() {
      this.updateSchedule();
    },
    createSchedule() {
      // 개별일정에는 planParamId가 존재하지 않는다!
      const newSchedule: ScheduleParam = {
        paramId: `schedule${Math.random()}`,
        title: this.form.title,
        appliedAllItems: this.form.appliedAllItems,
        saleTerm: this.form.saleTerm,
        term: {
          startedAt: this.form.term.startedAt,
          endedAt: this.form.term.endedAt,
          duration: this.form.term.endedAt - this.form.term.startedAt,
        },
        minimumQuota: this.form.minimumQuota,
        quota: this.form.quota,
        itemParamIds: this.selectedItems.map(item => item.paramId),
        maximumPurchasableCount: this.schedule.maximumPurchasableCount,
        edited: false,
      };
      this.$emit('addSchedule', newSchedule);
    },
    showUpdatePlanForm() {
      this.$emit('showUpdatePlanForm', this.form.planParamId);
    },
    isTodayFrip(firstStartedAt: number): boolean {
      const today = new Date();
      const fripStartedAt = new Date(firstStartedAt);

      return (
        this.statusLabel.text === '등록중' &&
        today.getFullYear() === fripStartedAt.getFullYear() &&
        today.getMonth() === fripStartedAt.getMonth() &&
        today.getDate() === fripStartedAt.getDate()
      );
    },
    validateForm(): boolean {
      let isValid = true;
      console.log(
        this.form.term.startedAt - this.form.saleTerm.endedAt,
        DAY_MILLISECOND
      );

      if (this.isTodayFrip(this.form.term.startedAt)) {
        this.$modal.show({
          message:
            '당일 일정으로 수정할 수 없습니다. 운영 기간을 수정해주세요.',
          type: 'danger',
        });
        isValid = false;
      } else if (this.form.term.startedAt >= this.form.term.endedAt) {
        this.$modal.show({
          message:
            '프립 진행 종료 시간은 프립 진행 시작 시간보다 빠를 수 없습니다.',
          type: 'danger',
        });
        isValid = false;
      } else if (this.form.term.endedAt > this.$data.saleTermEndedAt) {
        this.$modal.show({
          message: '3개월 이내의 일정만 추가 가능합니다.',
          type: 'danger',
        });
        isValid = false;
      } else if (this.form.saleTerm.endedAt > this.form.term.startedAt) {
        this.$modal.show({
          message: '신청 마감일은 프립 진행 시작 시간보다 미래일 수 없습니다.',
          type: 'danger',
        });
        isValid = false;
      } else if (this.form.minimumQuota > this.form.quota) {
        this.$modal.show({
          message: '최소 모집 인원은 최대 모집 인원보다 많을 수 없습니다.',
          type: 'danger',
        });
        isValid = false;
      } else if (
        (this.form.term.startedAt - this.form.saleTerm.endedAt <
          DAY_MILLISECOND ||
          this.bookingConfirmationEnabled) &&
        this.form.minimumQuota >= 2
      ) {
        const invalidMessage = this.bookingConfirmationEnabled
          ? `개별 예약 확정을 하는 프립은 </br>최소 인원을 1명으로만 설정하실 수 있습니다.`
          : ` 최소 모집 인원이 다 모이지 않을 경우 일정이 자동 취소됩니다.</br> 이를 방지하기 위해 최소 인원은 1명으로만 설정하실 수 있습니다. </br>최소 인원을 1명으로 변경해주세요.`;
        this.$modal.show({
          html: invalidMessage,
          type: 'danger',
        });

        isValid = false;
      } else if (
        this.schedule.status != 'undefined' && // 작성중이 아닌경우
        this.schedule.quota > this.form.quota // 변경시 값이 작아질 경우
      ) {
        // console.log("원본:" + this.schedule.quota)
        // console.log("변경:" + this.form.quota)
        // console.log("스케쥴상태:" + this.schedule.status)

        this.$modal.show({
          html: '현재 예약된 인원 수를 반드시 확인 후 수정해주세요',
          type: 'danger',
        });

        // this.form.quota = this.schedule.quota
        // isValid = false;
      }
      return isValid;
    },
    updateSchedule() {
      // TODO: 기본 schedule과 다른지 체크해야함
      const isValid = this.validateForm();
      if (isValid) {
        const schedule: ScheduleParam = {
          id: this.schedule.id, // 만약 상품 수정중이라면 schdule에 아이디가 존재
          appliedAllItems: this.form.appliedAllItems,
          paramId: this.schedule.paramId,
          title: this.form.title,
          saleTerm: this.form.saleTerm,
          term: {
            startedAt: this.form.term.startedAt,
            endedAt: this.form.term.endedAt,
            duration: this.form.term.endedAt - this.form.term.startedAt,
          },
          minimumQuota: this.form.minimumQuota,
          quota: this.form.quota,
          itemParamIds: this.form.appliedAllItems
            ? []
            : this.selectedItems.map(item => item.paramId),
          planParamId: this.schedule.planParamId,
          maximumPurchasableCount: this.schedule.maximumPurchasableCount,
          status: this.schedule.status,
          edited: true, // 수정 했음을 표시
        };
        if (!this.form.appliedAllItems) {
          if (this.selectedItems.length === 0) {
            this.$modal.show({
              html: `일정은 하나 이상의 옵션을 선택해야 합니다.`,
              type: 'danger',
            });
            return;
          }
        }
        this.$emit('update', schedule);
      }
    },
    pauseSchedule() {
      this.$modal.show(
        {
          title: '일정의 판매를 일시 중지 합니다.',
          message: '일정의 판매를 일시중지 합니다.',
          showCancelButton: true,
        },
        () => {
          this.$emit('pause', this.form.id);
        }
      );
    },
    openSchedule() {
      this.$modal.show(
        {
          title: '일정을 재판매 합니다.',
          message: '일정의 판매를 시작합니다.',
          showCancelButton: true,
        },
        () => {
          this.$emit('open', this.form.id);
        }
      );
    },
    deleteSchedule() {
      if (this.canEdit) {
        this.$emit('delete', this.schedule.paramId);
      }
    },
    closeScheduleForm() {
      this.$emit('close');
    },
    showItemSelectModal() {
      this.itemSelectModal = true;
    },
    closeItemSelectModal() {
      this.itemSelectModal = false;
    },
  },
});
